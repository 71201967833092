import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { KeyStrokeConfig, useKeystrokes } from "@hooks/useKeystrokes";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import {
  ComponentProps,
  FC,
  FormEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
} from "react";
import ReactTextareaAutosize from "react-textarea-autosize";

export type onTextChangeProp = (
  text: string,
  e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>,
) => void;
export type TextFieldProps = {
  text: string;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  onTextChange?: onTextChangeProp;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  keyStrokeConfig?: KeyStrokeConfig;
  height?: "40px" | "32px";
  width?: React.CSSProperties["width"];
  label?: string;
  labelTextProps?: Partial<ComponentProps<typeof Text>>;
  subtext?: string;
  leftIcon?: DeprecatedIconType;
  rightIcon?: DeprecatedIconType;
  containerStyleDeclaration?: StyleDeclaration;
  wrapperStyleDeclaration?: StyleDeclaration;
  inputStyleDeclaration?: StyleDeclaration;
  labelStyleDeclaration?: StyleDeclaration;
  inputType?: HTMLInputTypeAttribute;
  disabled?: boolean;
  error?: boolean;
  hideBorder?: boolean;
  autoFocus?: boolean;
  max?: React.InputHTMLAttributes<HTMLInputElement>["max"];
  min?: React.InputHTMLAttributes<HTMLInputElement>["min"];
  dataTestId?: string;
  containerStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  required?: boolean;
};

const TextField: FC<TextFieldProps> = (
  {
    text,
    multiline = false,
    placeholder = "",
    onTextChange,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    onKeyDown,
    keyStrokeConfig,
    height = "32px",
    width = "100%",
    label,
    labelTextProps,
    subtext,
    leftIcon,
    rightIcon,
    containerStyleDeclaration,
    labelStyleDeclaration,
    wrapperStyleDeclaration,
    inputStyleDeclaration,
    inputType = "text",
    disabled = false,
    error = false,
    hideBorder = false,
    autoFocus,
    max,
    min,
    dataTestId,
    containerStyle,
    wrapperStyle,
    inputStyle,
    minRows,
    maxRows,
    required,
  },
  ref,
) => {
  useKeystrokes(keyStrokeConfig);
  return (
    <div
      className={css(textFieldStyles.container, containerStyleDeclaration)}
      style={{ width, ...containerStyle }}
    >
      {label && (
        <label
          className={css(textFieldStyles.label, labelStyleDeclaration)}
          htmlFor={text}
        >
          <Text
            type={"P3"}
            fontWeight={"SemiBold"}
            color={deprecatedColors.onBackground}
            {...labelTextProps}
          >
            {label}
            {required && "*"}
          </Text>
        </label>
      )}
      <div
        className={css(textFieldStyles.wrapper, wrapperStyleDeclaration)}
        style={{ ...wrapperStyle }}
      >
        {!multiline && (
          <input
            ref={ref}
            type={inputType}
            disabled={disabled}
            max={max}
            min={min}
            className={css(
              textFieldStyles.input,
              textFieldStyles.default,
              error && textFieldStyles.error,
              hideBorder && textFieldStyles.hideBorder,
              textFieldHeightStyles[height],
              leftIcon ? inputLeftIcon[height] : undefined,
              rightIcon ? inputRightIcon[height] : undefined,
              inputStyleDeclaration,
            )}
            style={{ height, ...inputStyle }}
            placeholder={placeholder}
            value={text}
            onFocus={onFocus && (() => onFocus())}
            onMouseEnter={onMouseEnter && (() => onMouseEnter())}
            onMouseLeave={onMouseLeave && (() => onMouseLeave())}
            onInput={
              onTextChange &&
              ((e) => onTextChange((e.target as HTMLInputElement).value, e))
            }
            onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : {})}
            autoFocus={autoFocus}
            data-testid={dataTestId}
          />
        )}
        {multiline && (
          <ReactTextareaAutosize
            ref={ref}
            disabled={disabled}
            className={css(
              textFieldStyles.input,
              textFieldStyles.multilineInput,
              textFieldStyles.default,
              error && textFieldStyles.error,
              hideBorder && textFieldStyles.hideBorder,
              textFieldHeightStyles[height],
              leftIcon ? inputLeftIcon[height] : undefined,
              rightIcon ? inputRightIcon[height] : undefined,
              inputStyleDeclaration,
            )}
            placeholder={placeholder}
            value={text}
            onFocus={onFocus && (() => onFocus())}
            onMouseEnter={onMouseEnter && (() => onMouseEnter())}
            onMouseLeave={onMouseLeave && (() => onMouseLeave())}
            onInput={
              onTextChange &&
              ((e) => onTextChange((e.target as HTMLTextAreaElement).value, e))
            }
            autoFocus={autoFocus}
            minRows={minRows}
            maxRows={maxRows}
            data-testid={dataTestId}
          />
        )}
        {leftIcon && (
          <DeprecatedIcon
            type={leftIcon}
            color={deprecatedTones.gray8}
            styleDeclaration={[textFieldStyles.icon, leftIconStyle[height]]}
          />
        )}
        {rightIcon && (
          <DeprecatedIcon
            type={rightIcon}
            color={deprecatedTones.gray8}
            styleDeclaration={[textFieldStyles.icon, rightIconStyle[height]]}
          />
        )}
      </div>
      {subtext && (
        <Text
          type="P3"
          fontWeight="Medium"
          color={error ? deprecatedTones.red9 : deprecatedTones.gray7}
          styleDeclaration={textInputSubtextStyle[height]}
        >
          {subtext}
        </Text>
      )}
    </div>
  );
};

// @ts-ignore
export default forwardRef(TextField);

export const textFieldStyles = StyleSheet.create({
  container: {
    display: "inline-flex",
    flexDirection: "column",
  },
  wrapper: {
    position: "relative",
    width: "100%",
  },
  input: {
    width: "100%",
    outline: "none",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    borderRadius: 10,
    "::placeholder": {
      color: deprecatedTones.gray6,
    },
    color: deprecatedTones.black,
    fontWeight: 400,
    fontSize: 14,
    height: 36,
    backgroundColor: deprecatedColors.onPrimary,
    boxSizing: "border-box",
    textOverflow: "ellipsis",
    fontFamily: "Inter",
  },
  multilineInput: {
    resize: "none",
  },
  default: {
    border: `1px solid ${deprecatedTones.gray2}`,
    ":focus": {
      border: `1px solid ${deprecatedTones.blue3}`,
    },
    ":disabled": {
      border: `1px solid ${deprecatedTones.gray5Alpha}`,
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  error: {
    border: `1px solid ${deprecatedTones.red9}`,
    ":hover": {
      border: `1px solid ${deprecatedTones.red9}`,
    },
    ":focus": {
      border: `1px solid ${deprecatedTones.red9}`,
    },
  },
  hideBorder: {
    border: "1px solid transparent",
    ":hover": {
      border: "1px solid transparent",
    },
    ":focus": {
      border: "1px solid transparent",
    },
    ":disabled": {
      border: "1px solid transparent",
    },
  },
  icon: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  leftIconPx32: {
    left: 6,
  },
  rightIconPx32: {
    right: 4,
  },
  leftIconPx40: {
    left: 8,
  },
  rightIconPx40: {
    right: 8,
  },
  label: {
    marginBottom: 4,
  },
  subtextPx32: {
    margin: `4px 0 0 0`,
  },
  subtextPx40: {
    margin: `4px 0 0 0`,
  },
  px32: {
    padding: "0 8px",
  },
  px40: {
    padding: "0 10px",
  },
  inputLeftIconPx32: {
    paddingLeft: 30,
  },
  inputLeftIconPx40: {
    paddingLeft: 32,
  },
  inputRightIconPx32: {
    paddingRight: 32,
  },
  inputRightIconPx40: {
    paddingRight: 34,
  },
});

export const textFieldHeightStyles = {
  ["32px"]: textFieldStyles.px32,
  ["40px"]: textFieldStyles.px40,
};

const inputLeftIcon = {
  ["32px"]: textFieldStyles.inputLeftIconPx32,
  ["40px"]: textFieldStyles.inputLeftIconPx40,
};

const inputRightIcon = {
  ["32px"]: textFieldStyles.inputRightIconPx32,
  ["40px"]: textFieldStyles.inputRightIconPx40,
};

const leftIconStyle = {
  ["32px"]: textFieldStyles.leftIconPx32,
  ["40px"]: textFieldStyles.leftIconPx40,
};

const rightIconStyle = {
  ["32px"]: textFieldStyles.rightIconPx32,
  ["40px"]: textFieldStyles.rightIconPx40,
};

export const textInputSubtextStyle = {
  ["32px"]: textFieldStyles.subtextPx32,
  ["40px"]: textFieldStyles.subtextPx40,
};
