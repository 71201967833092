import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import React, { FC } from "react";
import { StyleSheet } from "aphrodite";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Text from "@ui/text";
import EmojiPicker from "./EmojiPicker";
import BackgroundGradientIcon from "./BackgroundGradientIcon";
import { gradientStyles } from "./CoverImage";
import { CoverImageFragment } from "@src/fragments.generated";
import Button from "@src/deprecatedDesignSystem/components/Button";
import { BackgroundGradient } from "@src/types.generated";

type Props = {
  coverImage?: CoverImageFragment;
  selectedGradient: BackgroundGradient | null | undefined;
  setSelectedGradient: (gradient: BackgroundGradient) => void;
  emoji: string;
  setEmoji: (emoji: string) => void;
  clearEmoji: () => void;
};

const backgroundGradients = [
  BackgroundGradient.Gradient_1,
  BackgroundGradient.Gradient_2,
  BackgroundGradient.Gradient_3,
  BackgroundGradient.Gradient_4,
  BackgroundGradient.Gradient_5,
  BackgroundGradient.Gradient_6,
  BackgroundGradient.Gradient_7,
  BackgroundGradient.Gradient_8,
  BackgroundGradient.Gradient_9,
  BackgroundGradient.Gradient_10,
];

const CoverImagePickerEmojiTab: FC<Props> = ({
  coverImage,
  selectedGradient,
  setSelectedGradient,
  emoji,
  setEmoji,
  clearEmoji,
}) => {
  return (
    <AutoLayout
      styleDeclaration={styles.emojiTabContainer}
      direction="vertical"
    >
      <AutoLayout direction="vertical" height={60} marginTop={8}>
        <Text type="P3">Background</Text>
        <AutoLayout direction="horizontal" marginTop={8}>
          {backgroundGradients.map((gradient, index) => (
            <BackgroundGradientIcon
              key={index}
              gradientStyle={gradientStyles[gradient]}
              selected={
                selectedGradient == gradient ||
                coverImage?.background == gradient
              }
              onClick={() => setSelectedGradient(gradient)}
            />
          ))}
        </AutoLayout>
      </AutoLayout>
      <AutoLayout
        direction="vertical"
        marginTop={8}
        marginBottom={8}
        styleDeclaration={styles.emojiContainerWrapper}
      >
        <AutoLayout
          direction="horizontal"
          spacingMode="space-between"
          alignmentVertical="center"
          alignmentHorizontal="center"
          width={"100%"}
          height={32}
        >
          {" "}
          <Text styleDeclaration={styles.emojiText} type="P3">
            Emoji
          </Text>
          {emoji !== "" && (
            <Button
              variant="No Outline"
              text="clear"
              leftIcon="close"
              textType="P3"
              textFontWeight="Regular"
              onClick={() => clearEmoji()}
              styleDeclaration={styles.clearButton}
            />
          )}
        </AutoLayout>
        <AutoLayout styleDeclaration={styles.emojiContainer}>
          <EmojiPicker
            enableFrequentEmojiSort={false}
            onEmojiSelect={setEmoji}
            navPosition={"none"}
            previewPosition={"none"}
            skinTonePosition={"search"}
            showFrequentCategory={false}
          />
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};

export default CoverImagePickerEmojiTab;

const styles = StyleSheet.create({
  emojiTabContainer: {
    height: "100%",
    width: "100%",
  },
  emojiContainerWrapper: {
    overflow: "auto",
  },
  emojiContainer: {
    maxHeight: 400,
    marginTop: 5,
  },
  emojiText: {
    backgroundColor: "white",
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: -10,
    zIndex: 9999,
    width: "100%",
  },
  clearButton: {
    fontColor: deprecatedColors.primary,
    backgroundColor: "white",
    paddingBottom: 5,
    marginBottom: -10,
    zIndex: 9999,
  },
});
