import { RefObject, useCallback, useEffect, useState } from "react";

type Return = {
  disableClickOutside: (ms?: number) => void;
};

const useClickOutside = (
  ref: RefObject<HTMLDivElement>,
  callback: (e: MouseEvent) => void,
  capture = true,
): Return => {
  const [enabled, setEnabled] = useState(true);
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (
        enabled &&
        ref.current?.contains &&
        !ref.current.contains(e.target as Node)
      ) {
        callback(e);
      }
    },
    [enabled, ref, callback],
  );
  const disableClickOutsideNextUpdate = useCallback(
    (ms?: number) => {
      setEnabled(false);
      setTimeout(() => setEnabled(true), ms || 0);
    },
    [setEnabled],
  );
  useEffect(() => {
    document.addEventListener("click", handleClick, { capture });

    return () => {
      document.removeEventListener("click", handleClick, { capture });
    };
  }, [handleClick, capture]);
  return {
    disableClickOutside: disableClickOutsideNextUpdate,
  };
};

export default useClickOutside;
