import React, { FC, CSSProperties, ReactNode, MouseEvent } from "react";
import NextLink from "next/link";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { DeprecateTone, DeprecatedColor } from "../styles/deprecatedColors";
import Text from "@ui/text";
import { Route } from "nextjs-routes";

type Props = {
  href?: Route;
  externalUrl?: string;
  children?: ReactNode | string;
  text?: string;
  type?: "H1" | "H2" | "H3" | "P1" | "P2" | "P3" | "P4";
  color?: DeprecateTone | DeprecatedColor;
  multiline?: boolean;
  underline?: boolean;
  fontWeight?: "Regular" | "Medium" | "SemiBold" | "Bold";
  loading?: boolean;
  target?: "_blank" | "_self" | "_parent" | "_top";
  rel?: string;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  onClick?: () => void;
};

const Link: FC<Props> = ({
  href,
  externalUrl,
  text,
  type = "P1",
  color,
  multiline = false,
  underline = false,
  children,
  fontWeight,
  loading = false,
  target,
  rel,
  style,
  styleDeclaration,
  ...props
}): JSX.Element => {
  return (
    <NextLink
      // @ts-ignore
      href={externalUrl || href}
      className={css(
        styles.link,
        underline ? styles.underline : styles.noUnderline,
        styleDeclaration,
      )}
      style={style}
      target={target}
      rel={rel}
      {...props}
    >
      {children ? (
        children
      ) : (
        <Text
          type={type}
          fontWeight={fontWeight}
          color={color}
          multiline={multiline}
          loading={loading}
        >
          {text}
        </Text>
      )}
    </NextLink>
  );
};

const styles = StyleSheet.create({
  link: {
    color: "unset",
  },
  noUnderline: {
    textDecoration: "none",
  },
  underline: {
    textDecoration: "underline",
  },
});

export default Link;
