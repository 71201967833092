import { useRef, useEffect } from "react";
import type { MutableRefObject } from "react";

export function usePrevious<T>(
  value: T,
): MutableRefObject<T | undefined>["current"] {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function usePreviousNonNullOrUndefined<T>(
  value: T | undefined | null,
): T | undefined {
  const ref = useRef<T | undefined>(undefined);
  useEffect(() => {
    if (value !== null && value !== undefined) {
      ref.current = value;
    }
  }, [value]);
  return ref.current;
}
