import {
  InitQuery,
  MeFragment,
  useInitQuery,
} from "@components/layout/operations.generated";
import React, { useMemo } from "react";

export interface UserContextState {
  user: MeFragment["user"] | undefined;
  isStaff: boolean;
}

const UserContext = React.createContext<UserContextState>({
  user: undefined,
  isStaff: false,
});

export const prepContext = (data: InitQuery): UserContextState => {
  return {
    user: data.Me?.user,
    isStaff: data.Me?.isStaff || false,
  };
};

export default UserContext;

export const useUserContextState = (): UserContextState => {
  const { data } = useInitQuery();
  return useMemo(() => {
    if (!data) {
      return {
        user: undefined,
        isStaff: false,
      };
    }
    return prepContext(data);
  }, [data]);
};
