import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { UnsplashImageFragmentDoc, EmployeeThinFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchUnsplashImagesMutationVariables = Types.Exact<{
  searchText: Types.Scalars['String'];
  page: Types.Scalars['Int'];
  orientation?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SearchUnsplashImagesMutation = { __typename?: 'Mutation', searchUnsplashImages: { __typename?: 'SearchUnsplashMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null, images: Array<{ __typename?: 'UnsplashImage', id: string, altDescription?: string | null, urls: { __typename?: 'UnsplashUrls', full: string, regular: string, small: string, thumb: string }, user: { __typename?: 'UnsplashUser', id: string, name: string, links: { __typename?: 'UnsplashUserLinks', html: string } }, links: { __typename?: 'UnsplashLinks', downloadLocation: string } }> } };

export type AttributeUnsplashImageSelectionMutationVariables = Types.Exact<{
  downloadUrl: Types.Scalars['String'];
}>;


export type AttributeUnsplashImageSelectionMutation = { __typename?: 'Mutation', attributeUnsplashImageSelection: { __typename?: 'GenericMutationResult', success: boolean } };

export type GetLocationsWithEmployeesQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  input?: Types.InputMaybe<Types.LocationsInput>;
}>;


export type GetLocationsWithEmployeesQuery = { __typename?: 'Query', Locations: { __typename?: 'Locations', totalCount: number, objects: Array<{ __typename?: 'Location', id: number, name: string, employees: Array<{ __typename?: 'Employee', id: number, name: string, phoneNumber?: string | null, language: Language, userType: Types.UserType }> }> } };

export type GetRolesWithEmployeesQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  input?: Types.InputMaybe<Types.RolesInput>;
}>;


export type GetRolesWithEmployeesQuery = { __typename?: 'Query', Roles: { __typename?: 'Roles', totalCount: number, objects: Array<{ __typename?: 'Role', id: number, name: string, employees: Array<{ __typename?: 'Employee', id: number, name: string, phoneNumber?: string | null, language: Language, userType: Types.UserType }> }> } };

export type GetAllEmployeesQueryVariables = Types.Exact<{
  userTypes?: Types.InputMaybe<Array<Types.UserType> | Types.UserType>;
}>;


export type GetAllEmployeesQuery = { __typename?: 'Query', Employees: { __typename?: 'Employees', objects: Array<{ __typename?: 'Employee', id: number, name: string, userType: Types.UserType }> } };

export type MinimalEmployeeFragment = { __typename?: 'Employee', id: number, name: string, userType: Types.UserType };

export const MinimalEmployeeFragmentDoc = gql`
    fragment MinimalEmployee on Employee {
  id
  name
  userType
}
    `;
export const SearchUnsplashImagesDocument = gql`
    mutation searchUnsplashImages($searchText: String!, $page: Int!, $orientation: String) {
  searchUnsplashImages(
    searchText: $searchText
    page: $page
    orientation: $orientation
  ) {
    success
    error {
      code
    }
    images {
      ...UnsplashImage
    }
  }
}
    ${UnsplashImageFragmentDoc}`;
export type SearchUnsplashImagesMutationFn = Apollo.MutationFunction<SearchUnsplashImagesMutation, SearchUnsplashImagesMutationVariables>;

/**
 * __useSearchUnsplashImagesMutation__
 *
 * To run a mutation, you first call `useSearchUnsplashImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchUnsplashImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchUnsplashImagesMutation, { data, loading, error }] = useSearchUnsplashImagesMutation({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      page: // value for 'page'
 *      orientation: // value for 'orientation'
 *   },
 * });
 */
export function useSearchUnsplashImagesMutation(baseOptions?: Apollo.MutationHookOptions<SearchUnsplashImagesMutation, SearchUnsplashImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchUnsplashImagesMutation, SearchUnsplashImagesMutationVariables>(SearchUnsplashImagesDocument, options);
      }
export type SearchUnsplashImagesMutationHookResult = ReturnType<typeof useSearchUnsplashImagesMutation>;
export type SearchUnsplashImagesMutationResult = Apollo.MutationResult<SearchUnsplashImagesMutation>;
export type SearchUnsplashImagesMutationOptions = Apollo.BaseMutationOptions<SearchUnsplashImagesMutation, SearchUnsplashImagesMutationVariables>;
export const AttributeUnsplashImageSelectionDocument = gql`
    mutation attributeUnsplashImageSelection($downloadUrl: String!) {
  attributeUnsplashImageSelection(downloadUrl: $downloadUrl) {
    success
  }
}
    `;
export type AttributeUnsplashImageSelectionMutationFn = Apollo.MutationFunction<AttributeUnsplashImageSelectionMutation, AttributeUnsplashImageSelectionMutationVariables>;

/**
 * __useAttributeUnsplashImageSelectionMutation__
 *
 * To run a mutation, you first call `useAttributeUnsplashImageSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeUnsplashImageSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeUnsplashImageSelectionMutation, { data, loading, error }] = useAttributeUnsplashImageSelectionMutation({
 *   variables: {
 *      downloadUrl: // value for 'downloadUrl'
 *   },
 * });
 */
export function useAttributeUnsplashImageSelectionMutation(baseOptions?: Apollo.MutationHookOptions<AttributeUnsplashImageSelectionMutation, AttributeUnsplashImageSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeUnsplashImageSelectionMutation, AttributeUnsplashImageSelectionMutationVariables>(AttributeUnsplashImageSelectionDocument, options);
      }
export type AttributeUnsplashImageSelectionMutationHookResult = ReturnType<typeof useAttributeUnsplashImageSelectionMutation>;
export type AttributeUnsplashImageSelectionMutationResult = Apollo.MutationResult<AttributeUnsplashImageSelectionMutation>;
export type AttributeUnsplashImageSelectionMutationOptions = Apollo.BaseMutationOptions<AttributeUnsplashImageSelectionMutation, AttributeUnsplashImageSelectionMutationVariables>;
export const GetLocationsWithEmployeesDocument = gql`
    query GetLocationsWithEmployees($limit: Int, $offset: Int, $input: LocationsInput) {
  Locations(pagination: {limit: $limit, offset: $offset}, input: $input) {
    totalCount
    objects {
      id
      name
      employees {
        ...EmployeeThin
      }
    }
  }
}
    ${EmployeeThinFragmentDoc}`;

/**
 * __useGetLocationsWithEmployeesQuery__
 *
 * To run a query within a React component, call `useGetLocationsWithEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsWithEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsWithEmployeesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLocationsWithEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsWithEmployeesQuery, GetLocationsWithEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsWithEmployeesQuery, GetLocationsWithEmployeesQueryVariables>(GetLocationsWithEmployeesDocument, options);
      }
export function useGetLocationsWithEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsWithEmployeesQuery, GetLocationsWithEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsWithEmployeesQuery, GetLocationsWithEmployeesQueryVariables>(GetLocationsWithEmployeesDocument, options);
        }
export type GetLocationsWithEmployeesQueryHookResult = ReturnType<typeof useGetLocationsWithEmployeesQuery>;
export type GetLocationsWithEmployeesLazyQueryHookResult = ReturnType<typeof useGetLocationsWithEmployeesLazyQuery>;
export type GetLocationsWithEmployeesQueryResult = Apollo.QueryResult<GetLocationsWithEmployeesQuery, GetLocationsWithEmployeesQueryVariables>;
export const GetRolesWithEmployeesDocument = gql`
    query GetRolesWithEmployees($limit: Int, $offset: Int, $input: RolesInput) {
  Roles(pagination: {limit: $limit, offset: $offset}, input: $input) {
    totalCount
    objects {
      id
      name
      employees {
        ...EmployeeThin
      }
    }
  }
}
    ${EmployeeThinFragmentDoc}`;

/**
 * __useGetRolesWithEmployeesQuery__
 *
 * To run a query within a React component, call `useGetRolesWithEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesWithEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesWithEmployeesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRolesWithEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesWithEmployeesQuery, GetRolesWithEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesWithEmployeesQuery, GetRolesWithEmployeesQueryVariables>(GetRolesWithEmployeesDocument, options);
      }
export function useGetRolesWithEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesWithEmployeesQuery, GetRolesWithEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesWithEmployeesQuery, GetRolesWithEmployeesQueryVariables>(GetRolesWithEmployeesDocument, options);
        }
export type GetRolesWithEmployeesQueryHookResult = ReturnType<typeof useGetRolesWithEmployeesQuery>;
export type GetRolesWithEmployeesLazyQueryHookResult = ReturnType<typeof useGetRolesWithEmployeesLazyQuery>;
export type GetRolesWithEmployeesQueryResult = Apollo.QueryResult<GetRolesWithEmployeesQuery, GetRolesWithEmployeesQueryVariables>;
export const GetAllEmployeesDocument = gql`
    query GetAllEmployees($userTypes: [UserType!]) {
  Employees: AdminEmployees(userTypes: $userTypes) {
    objects {
      ...MinimalEmployee
    }
  }
}
    ${MinimalEmployeeFragmentDoc}`;

/**
 * __useGetAllEmployeesQuery__
 *
 * To run a query within a React component, call `useGetAllEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployeesQuery({
 *   variables: {
 *      userTypes: // value for 'userTypes'
 *   },
 * });
 */
export function useGetAllEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>(GetAllEmployeesDocument, options);
      }
export function useGetAllEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>(GetAllEmployeesDocument, options);
        }
export type GetAllEmployeesQueryHookResult = ReturnType<typeof useGetAllEmployeesQuery>;
export type GetAllEmployeesLazyQueryHookResult = ReturnType<typeof useGetAllEmployeesLazyQuery>;
export type GetAllEmployeesQueryResult = Apollo.QueryResult<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>;