import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { CSSProperties, FC } from "react";

type Props = {
  style?: CSSProperties;
  onEmojiSelect: (emoji: string) => void;
  enableFrequentEmojiSort?: boolean;
  navPosition?: "top" | "bottom" | "none";
  previewPosition?: "top" | "bottom" | "none";
  skinTonePosition?: "preview" | "search" | "none";
  showFrequentCategory?: boolean;
  autoFocus?: boolean;
};

const categories = [
  "frequent",
  "people",
  "nature",
  "foods",
  "activity",
  "places",
  "objects",
  "symbols",
  "flags",
];
const categoriesNoFrequent = [
  "people",
  "nature",
  "foods",
  "activity",
  "places",
  "objects",
  "symbols",
  "flags",
];

const EmojiPicker: FC<Props> = ({
  onEmojiSelect,
  style,
  enableFrequentEmojiSort,
  navPosition = "top",
  previewPosition = "bottom",
  skinTonePosition = "preview",
  showFrequentCategory = true,
  autoFocus,
}) => {
  return (
    <Picker
      data={data}
      autoFocus={autoFocus}
      theme="light"
      navPosition={navPosition}
      previewPosition={previewPosition}
      skinTonePosition={skinTonePosition}
      enableFrequentEmojiSort={enableFrequentEmojiSort}
      color={deprecatedColors.primary}
      categories={showFrequentCategory ? categories : categoriesNoFrequent}
      title=""
      emoji="face_with_cowboy_hat"
      style={style}
      onEmojiSelect={(emoji: { native: string }) => onEmojiSelect(emoji.native)}
    />
  );
};

export default EmojiPicker;
