import { CSSProperties, FC } from "react";
import { StyleSheet, css } from "aphrodite";
import { deprecatedTones } from "../styles/deprecatedColors";
import { shadows } from "../styles/shadows";
import Text from "@ui/text";

type ButtonProps = {
  state: "default" | "inactive";
  text: string;
  onClick?: () => void;
  onGray?: boolean;
  buttonWidth?: string;
  dataTestId?: string;
};

const buttonColors = {
  default: deprecatedTones.black,
  inactive: deprecatedTones.gray7,
};

const SegmentedControlButton: FC<ButtonProps> = ({
  state,
  text,
  onClick,
  onGray,
  buttonWidth,
  dataTestId,
}) => (
  <button
    style={{ width: buttonWidth }}
    className={css(
      styles.buttonContainer,
      onGray ? onGrayButtonStateStyles[state] : buttonStateStyles[state],
    )}
    onClick={onClick}
    data-testid={dataTestId}
  >
    <Text type="P2" fontWeight="Medium" color={buttonColors[state]}>
      {text}
    </Text>
  </button>
);

interface Option<T extends string> {
  value: T;
  label: string;
  dataTestId?: string;
}

interface Props<T extends string> {
  selectedOption: T;
  options: Option<T>[];
  onOptionSelected?: (option: T) => void;
  onGray?: boolean;
  style?: CSSProperties;
  buttonWidth?: string;
}

const SegmentedControl = <T extends string>({
  options,
  selectedOption,
  onOptionSelected,
  onGray,
  style,
  buttonWidth,
}: Props<T>): JSX.Element => {
  return (
    <div
      style={style}
      className={css(styles.container, onGray && styles.onGray)}
    >
      {options.map((option) => (
        <SegmentedControlButton
          key={`${option.value}-${option.label}`}
          state={option.value === selectedOption ? "default" : "inactive"}
          text={option.label}
          onClick={
            onOptionSelected ? () => onOptionSelected(option.value) : undefined
          }
          onGray={onGray}
          buttonWidth={buttonWidth}
          dataTestId={option.dataTestId}
        />
      ))}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "inline-flex",
    flexDirection: "row",
    height: 36,
    padding: 2,
    gap: 2,
    backgroundColor: deprecatedTones.gray1,
    borderRadius: 8,
    boxSizing: "border-box",
    flexShrink: 0,
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    padding: "0px 12px",
    border: "none",
    cursor: "pointer",
  },
  default: {
    backgroundColor: deprecatedTones.white,
    boxShadow: shadows.elevation1,
  },
  inactive: {
    backgroundColor: deprecatedTones.gray1,
  },
  onGray: {
    backgroundColor: deprecatedTones.gray1,
  },
});

const buttonStateStyles = {
  default: styles.default,
  inactive: styles.inactive,
};

const onGrayButtonStateStyles = {
  default: styles.default,
  inactive: styles.onGray,
};
export default SegmentedControl;
