import React, { ReactNode } from "react";

export interface ModalContextState {
  components: ReactNode[];
  showModal: (
    component: ReactNode | null,
    args?: { replace?: boolean },
  ) => void;
  closeModal: (args?: { closeAll?: boolean }) => void;
  modalIsOpen: boolean;
}

const ModalContext = React.createContext<ModalContextState>({
  components: [],
  showModal: () => {},
  closeModal: () => {},
  modalIsOpen: false,
});

export default ModalContext;
