import { useFeatureFlag } from "@hooks/useFeatureFlag";
import useUser from "@hooks/useUser";
import { LANGUAGES, STANDARD_LANGUAGES } from "@utils/strings";
import { useMemo } from "react";

type Return = {
  allLanguages: Language[];
  allNonEnglishLanguages: Language[];
  nonEnglishLanguagesInUse: Language[];
  nonEnglishLanguagesNotInUse: Language[];
  mostPopularNonEnglishLanguage: Language;
};

export const useAvailableLanguages = (): Return => {
  const { user } = useUser();
  const allLanguagesEnabled = useFeatureFlag("all_languages");

  const allLanguages = useMemo(() => {
    return allLanguagesEnabled ? LANGUAGES : STANDARD_LANGUAGES;
  }, [allLanguagesEnabled]);

  const allNonEnglishLanguages = useMemo(
    () => filterOutEnglish(allLanguages),
    [allLanguages],
  );

  const languagesInUse = useMemo(
    () => user?.org?.languagesInUse || [],
    [user?.org?.languagesInUse],
  );

  const nonEnglishLanguagesInUse = useMemo(
    () => filterOutEnglish(languagesInUse),
    [languagesInUse],
  );

  const mostPopularNonEnglishLanguage = useMemo(
    () => nonEnglishLanguagesInUse?.[0] || "es",
    [nonEnglishLanguagesInUse],
  );

  const nonEnglishLanguagesNotInUse = useMemo(
    () =>
      allNonEnglishLanguages.filter(
        (lang) => !nonEnglishLanguagesInUse.includes(lang),
      ),
    [allNonEnglishLanguages, nonEnglishLanguagesInUse],
  );

  return useMemo(() => {
    return {
      allLanguages,
      allNonEnglishLanguages,
      nonEnglishLanguagesInUse,
      nonEnglishLanguagesNotInUse,
      mostPopularNonEnglishLanguage,
    };
  }, [
    allLanguages,
    allNonEnglishLanguages,
    nonEnglishLanguagesInUse,
    nonEnglishLanguagesNotInUse,
    mostPopularNonEnglishLanguage,
  ]);
};

const filterOutEnglish = (languages: Language[]): Language[] =>
  languages.filter((lang) => lang !== "en");
