import { CoverImageFragment } from "@src/fragments.generated";
import CoverImagePicker from "@components/ui/CoverImagePicker";
import PinturaModal from "@components/ui/PinturaModal";
import Spacer from "@components/ui/Spacer";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import useHover from "@hooks/useHover";
import { useToast } from "@hooks/useToast";
import * as Popover from "@radix-ui/react-popover";
import { BackgroundGradient, CoverImageInput } from "@src/types.generated";
import { getCoverImageUrl } from "@utils/media";
import { StyleDeclaration, StyleSheet, css } from "aphrodite";
import { AnimatePresence, motion } from "framer-motion";
import { CSSProperties, FC, useMemo, useState } from "react";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";

type Props = {
  coverImage?: CoverImageFragment;
  isPath?: boolean;
  isSkill?: boolean;
  iconSize?: number;
  editEnabled: boolean;
  onNewCoverImageSelected?: (
    newCoverImage: Omit<CoverImageInput, "id">,
  ) => void;
  onCoverImageRemoved?: () => void;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  disableIcon?: boolean;
};

const CoverImage: FC<Props> = ({
  coverImage,
  isPath = false,
  isSkill = false,
  editEnabled,
  onNewCoverImageSelected,
  onCoverImageRemoved,
  style,
  styleDeclaration,
  iconSize,
  disableIcon,
}) => {
  const { addErrorToast } = useToast();
  const [open, setOpen] = useState(false);
  const [showPinturaModal, setShowPinturaModal] = useState(false);
  const coverImageUrl = useMemo(
    () => (coverImage ? getCoverImageUrl(coverImage) : null),
    [coverImage],
  );
  const fullSizeUrl = useMemo(
    () =>
      coverImage?.imageUrls?.medium ||
      coverImage?.imageUrls?.original ||
      coverImage?.imageUrls?.wide,
    [coverImage],
  );
  const displayIcon = useMemo(
    () => coverImageUrl == null && !disableIcon,
    [coverImageUrl, disableIcon],
  );
  const backgroundImage = useMemo(() => {
    const style = StyleSheet.create({
      image: {
        backgroundImage: `url(${coverImageUrl})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
    });
    return style.image;
  }, [coverImageUrl]);
  const [hover, hoverBind] = useHover();
  return (
    <AutoLayout
      direction="vertical"
      alignmentHorizontal="center"
      alignmentVertical="center"
      styleDeclaration={[
        styles.container,
        coverImage?.background && gradientStyles[coverImage.background],
        coverImageUrl && backgroundImage,
        styleDeclaration,
      ]}
      style={{ borderRadius: 16, ...style }}
      {...hoverBind}
    >
      {editEnabled && fullSizeUrl ? (
        <>
          <PinturaModal
            aspectRatio={2}
            onNewImageUrl={(imageUrl) => {
              onNewCoverImageSelected
                ? onNewCoverImageSelected({
                    mediaUrl: imageUrl,
                    emojiIcon: null,
                  })
                : addErrorToast({ message: "Unable to update cover image" });
            }}
            imageUrl={fullSizeUrl}
            visible={showPinturaModal}
            hide={() => setShowPinturaModal(false)}
          />
          <AutoLayout
            style={{
              backgroundColor: deprecatedTones.black,
              opacity: hover ? 0.7 : 0,
              cursor: "pointer",
              borderRadius: 16,
              transition: "opacity 0.2s ease",
            }}
            hoverStyle={{
              opacity: 0.8,
            }}
            alignmentVertical={"center"}
            paddingLeft={8}
            paddingRight={12}
            paddingVertical={4}
            onClick={() => setShowPinturaModal(true)}
            styleDeclaration={[styles.editImageButton]}
          >
            <DeprecatedIcon type={"pencil"} color={deprecatedTones.white} />
            <Spacer size={4} axis={"horizontal"} />
            <Text
              type={"P3"}
              fontWeight={"Bold"}
              color={deprecatedTones.white}
              style={{
                textTransform: "uppercase",
              }}
            >
              Edit Image
            </Text>
          </AutoLayout>
        </>
      ) : null}
      {coverImage?.emojiIcon && (
        <div
          style={{
            fontSize: iconSize || 64,
            textAlign: "center",
          }}
        >
          {coverImage.emojiIcon}
        </div>
      )}
      {!coverImage?.emojiIcon && displayIcon && (
        <DeprecatedIcon
          type={isPath ? "lines" : isSkill ? "asterisk" : "bookmark"}
          iconSize={iconSize || 64}
          containerSize={iconSize || 64}
          color={deprecatedColors.onPrimary}
        />
      )}
      {editEnabled && (
        <>
          {onCoverImageRemoved && coverImage?.imageUrls && (
            <AutoLayout
              style={{
                backgroundColor: deprecatedTones.black,
                opacity: hover ? 0.7 : 0,
                cursor: "pointer",
                borderRadius: 16,
                transition: "opacity 0.2s ease",
              }}
              hoverStyle={{
                opacity: 0.8,
              }}
              alignmentVertical={"center"}
              paddingLeft={8}
              paddingRight={12}
              paddingVertical={4}
              onClick={onCoverImageRemoved}
              styleDeclaration={[styles.removeCoverImageButton]}
            >
              <DeprecatedIcon type={"trash"} color={deprecatedTones.white} />
              <Spacer size={4} axis={"horizontal"} />
              <Text
                type={"P3"}
                fontWeight={"Bold"}
                color={deprecatedTones.white}
                style={{
                  textTransform: "uppercase",
                }}
              >
                Remove image
              </Text>
            </AutoLayout>
          )}
          <Popover.Root open={open} onOpenChange={(open) => setOpen(open)}>
            <Popover.Trigger asChild>
              <Popover.Anchor>
                <AutoLayout
                  style={{
                    backgroundColor: deprecatedTones.black,
                    opacity: hover ? 0.7 : 0,
                    cursor: "pointer",
                    borderRadius: 16,
                    transition: "opacity 0.2s ease",
                  }}
                  hoverStyle={{
                    opacity: 0.8,
                  }}
                  alignmentVertical={"center"}
                  paddingLeft={8}
                  paddingRight={12}
                  paddingVertical={4}
                  onClick={() => setOpen(true)}
                  styleDeclaration={[styles.changeCoverButton]}
                >
                  <DeprecatedIcon
                    type={"paintbucket-3"}
                    color={deprecatedTones.white}
                  />
                  <Spacer size={4} axis={"horizontal"} />
                  <Text
                    type={"P3"}
                    fontWeight={"Bold"}
                    color={deprecatedTones.white}
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Change Cover
                  </Text>
                </AutoLayout>
              </Popover.Anchor>
            </Popover.Trigger>
            <Popover.Portal>
              <AnimatePresence>
                <Popover.Content
                  asChild
                  sideOffset={40}
                  align="center"
                  className={css(styles.dropdown)}
                >
                  <motion.div
                    initial={{ scaleX: 1, scaleY: 0.6, opacity: 0 }}
                    animate={{
                      scaleY: 1,
                      opacity: 1,
                      transition: { type: "spring", duration: 0.2 },
                    }}
                    style={{ originY: 0, originX: 1 }}
                  >
                    <CoverImagePicker
                      coverImage={coverImage}
                      onCoverImageSelected={onNewCoverImageSelected}
                    />
                  </motion.div>
                </Popover.Content>
              </AnimatePresence>
            </Popover.Portal>
          </Popover.Root>
        </>
      )}
    </AutoLayout>
  );
};

export default CoverImage;

const styles = StyleSheet.create({
  container: {
    position: "relative",
    backgroundColor: deprecatedTones.gray5Alpha,
  },
  changeCoverButton: {
    position: "absolute",
    bottom: 12,
    right: 12,
  },
  removeCoverImageButton: {
    position: "absolute",
    bottom: 88,
    right: 12,
  },
  editImageButton: {
    position: "absolute",
    bottom: 50,
    right: 12,
  },
  dropdown: {
    zIndex: 9999,
    height: "100%",
    width: 400,
    position: "relative",
    overflow: "hidden",
    backgroundColor: deprecatedTones.white,
    borderRadius: 16,
    boxShadow: shadows.dropdownShadow,
  },
});

export const gradientStyles = StyleSheet.create({
  [BackgroundGradient.Gradient_1]: {
    background: "linear-gradient(to bottom right, #05A4FE, #E8BAFB)",
  },
  [BackgroundGradient.Gradient_2]: {
    background: "linear-gradient(to bottom right, #FFBBBA, #E8BAFB)",
  },
  [BackgroundGradient.Gradient_3]: {
    background: "linear-gradient(to bottom right, #FFADDE, #ADBFFD)",
  },
  [BackgroundGradient.Gradient_4]: {
    background: "linear-gradient(to bottom right, #E8BAFB, #EFF9DF)",
  },
  [BackgroundGradient.Gradient_5]: {
    background: "linear-gradient(to bottom right, #E8BAFB, #92EAEB)",
  },
  [BackgroundGradient.Gradient_6]: {
    background: "linear-gradient(to bottom right, #D6F7DB, #8EE8F7)",
  },
  [BackgroundGradient.Gradient_7]: {
    background: "linear-gradient(to bottom right, #92EAEB, #FFE375)",
  },
  [BackgroundGradient.Gradient_8]: {
    background: "linear-gradient(to bottom right, #FAD4CD, #FEABC4)",
  },
  [BackgroundGradient.Gradient_9]: {
    background: "linear-gradient(to bottom right, #FFE375, #FFBBBA)",
  },
  [BackgroundGradient.Gradient_10]: {
    background: "linear-gradient(to bottom right, #FFC48C, #FFADDE)",
  },
});

export const LINEAR_GRADIENTS: Record<BackgroundGradient, string> = {
  [BackgroundGradient.Gradient_1]:
    "linear-gradient(to bottom right, #05A4FE, #E8BAFB)",
  [BackgroundGradient.Gradient_2]:
    "linear-gradient(to bottom right, #FFBBBA, #E8BAFB)",
  [BackgroundGradient.Gradient_3]:
    "linear-gradient(to bottom right, #FFADDE, #ADBFFD)",
  [BackgroundGradient.Gradient_4]:
    "linear-gradient(to bottom right, #E8BAFB, #EFF9DF)",
  [BackgroundGradient.Gradient_5]:
    "linear-gradient(to bottom right, #E8BAFB, #92EAEB)",
  [BackgroundGradient.Gradient_6]:
    "linear-gradient(to bottom right, #D6F7DB, #8EE8F7)",
  [BackgroundGradient.Gradient_7]:
    "linear-gradient(to bottom right, #92EAEB, #FFE375)",
  [BackgroundGradient.Gradient_8]:
    "linear-gradient(to bottom right, #FAD4CD, #FEABC4)",
  [BackgroundGradient.Gradient_9]:
    "linear-gradient(to bottom right, #FFE375, #FFBBBA)",
  [BackgroundGradient.Gradient_10]:
    "linear-gradient(to bottom right, #FFC48C, #FFADDE)",
};
