import React, { FC, useEffect, useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { useTracking } from "../../hooks/useTracking";
import {
  useAttributeUnsplashImageSelectionMutation,
  useSearchUnsplashImagesMutation,
} from "./operations.generated";
import { UnsplashImageFragment } from "../../fragments.generated";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Link from "@src/deprecatedDesignSystem/components/Link";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";

type Props = {
  onImageSelect: (image: UnsplashImageFragment) => void;
  containerStyleDeclaration?: StyleDeclaration;
  imageContainerStyleDeclaration?: StyleDeclaration;
  imageStyleDeclaration?: StyleDeclaration;
  showPhotoCredit?: boolean;
  orientation?: string;
};

const UnsplashImageSearch: FC<Props> = ({
  onImageSelect,
  containerStyleDeclaration,
  showPhotoCredit = true,
  imageContainerStyleDeclaration,
  imageStyleDeclaration,
  orientation,
}) => {
  const [searchText, setSearchText] = useState("");
  const [images, setImages] = useState<UnsplashImageFragment[]>([]);
  const { trackEvent } = useTracking();
  const [searchUnsplashImagesMutation, { error }] =
    useSearchUnsplashImagesMutation({
      onCompleted: (data) => {
        if (data.searchUnsplashImages.images) {
          setImages(data.searchUnsplashImages.images);
        }
      },
    });
  const [attributeUnsplashedImageSelectionMutation] =
    useAttributeUnsplashImageSelectionMutation();
  const { debouncedFunc: debouncedSearch } = useDebounce({
    timeout: 100,
    wait: false,
    func: () => {
      trackEvent("unsplash_image_search", { searchText: searchText });
      searchUnsplashImagesMutation({
        variables: {
          searchText: searchText,
          page: 1,
          orientation: orientation,
        },
      });
    },
  });
  useEffect(() => {
    debouncedSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <div className={css(containerStyleDeclaration)}>
      <TextField
        text={searchText}
        onTextChange={setSearchText}
        placeholder="Search images..."
        containerStyleDeclaration={styles.textFieldStyles}
        leftIcon="search"
      />
      <div>
        {error && (
          <Text
            type="P2"
            color={deprecatedTones.gray6}
            styleDeclaration={styles.errorTextStyles}
          >
            Image search temporarily unavailable
          </Text>
        )}
        <div
          className={css([
            styles.imagesContainer,
            imageContainerStyleDeclaration,
          ])}
        >
          {images.map((image, i) => (
            <div
              key={i}
              className={css([
                styles.imageContainer,
                imageContainerStyleDeclaration,
              ])}
            >
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <img
                className={css([styles.imageStyles, imageStyleDeclaration])}
                src={image.urls.thumb}
                alt=""
                onClick={() => {
                  onImageSelect(image);
                  attributeUnsplashedImageSelectionMutation({
                    variables: {
                      downloadUrl: image.links.downloadLocation,
                    },
                  });
                }}
              />
              {showPhotoCredit && (
                <div className={css(styles.unsplashAttributionContainer)}>
                  <Text type="P3" color={deprecatedTones.gray8}>
                    by
                  </Text>
                  <Link
                    href={{
                      // @ts-ignore
                      pathname: `${image.user.links.html}?utm_source=242012&utm_medium=referral`,
                    }}
                    type="P3"
                    text={image.user.name}
                    color={deprecatedTones.gray8}
                    target="_blank"
                    rel="noreferred"
                    styleDeclaration={styles.linkStyles}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={css(styles.poweredByUnsplashContainer)}>
          <Text type="P2">Powered by</Text>
          <UnsplashLogo styleDeclaration={styles.svgContainer} />
        </div>
      </div>
    </div>
  );
};

export default UnsplashImageSearch;

type UnsplashLogoProps = {
  styleDeclaration?: StyleDeclaration;
};

const UnsplashLogo: FC<UnsplashLogoProps> = ({ styleDeclaration }) => (
  <svg
    height="18px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 457.19 104.19"
    className={css(styleDeclaration)}
  >
    <path d="M59.88 38.66h27.53v48.75H0V38.66h27.53v24.37h32.35zm93 25c0 8.25-5.45 13.13-12.9 13.13-7.28 0-12.81-4.88-12.81-13.13V24.41h-12.22v39.13c0 15.45 11 25.21 25.06 25.21s25.15-9.76 25.15-25.21V24.41h-12.25zm43.7-21.13c-4.7 0-9.94 2-12.6 6.57v-5.41h-11.45v43.64h11.81v-25.1c0-5 3-9 8.16-9 5.68 0 8.08 3.82 8.08 8.7v25.4h11.8V59.82c.03-9.59-4.94-17.31-15.77-17.31zm43.31 18.37l-6.48-1.33c-2.47-.5-4-1.77-4-3.9 0-2.49 2.23-4.35 5.33-4.35 4.36 0 6.09 2.25 6.51 4.88h10.18c-.08-6-4.83-13.84-16.51-13.84-9.41 0-16.33 6.47-16.33 14.28 0 6.13 3.81 11.19 12.24 13l6.05 1.33c3.37.71 4.7 2.31 4.7 4.26 0 2.31-2.14 4.35-6 4.35-4.71 0-7.27-2.68-7.87-5.79h-10.5c.59 6.53 5.32 14.84 18.46 14.84 11.45 0 17.22-7.28 17.22-14.38-.01-6.36-4.36-11.59-12.97-13.37zm63.19 4.53c0 13.22-8.26 23-20.59 23-6 0-10.48-2.4-12.61-5.33v21.13h-11.8V43.67h11.45v5.41c2-3.37 6.83-6.39 13.4-6.39 12.81 0 20.18 9.76 20.18 22.72zm-11.63.09c0-7.72-4.79-12.25-10.83-12.25s-10.91 4.53-10.91 12.25 4.88 12.33 10.91 12.33 10.91-4.54 10.91-12.35zm68-21.83h11.45v43.64h-11.8v-5.31c-2 3.5-6.57 6.38-12.61 6.38-12.33 0-20.59-9.77-20.59-23 0-13 7.37-22.72 20.15-22.72 6.57 0 11.32 3.05 13.4 6.39zm-.18 21.83c0-7.72-4.88-12.25-10.91-12.25s-10.83 4.51-10.83 12.23 4.79 12.33 10.83 12.33 10.92-4.6 10.92-12.33zm-50.66 21.81h11.8V24.41h-11.8zm132.35-44.81c-4.17 0-9 1.41-11.81 4.78V24.41h-11.8v62.91h11.8V61.68c.27-4.8 3.2-8.52 8.17-8.52 5.68 0 8.08 3.83 8.07 8.71v25.47h11.81V59.82c-.01-9.59-5.15-17.3-16.24-17.3zm-42 18.36l-6.43-1.33c-2.47-.5-4-1.77-4-3.9 0-2.49 2.22-4.35 5.33-4.35 4.35 0 6.08 2.25 6.5 4.88h10.17c-.08-6-4.83-13.84-16.51-13.84-9.41 0-16.33 6.47-16.33 14.28 0 6.13 3.82 11.19 12.25 13l6 1.33c3.37.71 4.7 2.31 4.7 4.26 0 2.31-2.14 4.35-6 4.35-4.71 0-7.27-2.68-7.87-5.79h-10.49c.58 6.53 5.31 14.84 18.45 14.84 11.45 0 17.22-7.28 17.22-14.38 0-6.34-4.35-11.57-12.95-13.35zM59.88 0H27.53v24.37h32.35z" />
  </svg>
);

const styles = StyleSheet.create({
  textFieldStyles: {
    marginTop: 20,
    marginBottom: 12,
  },
  imagesContainer: {
    margin: "0 -4px 4px -4px",
    columnCount: 4,
  },
  imageContainer: {
    breakInside: "avoid",
  },
  imageStyles: {
    padding: "4px 4px 0 4px",
    borderRadius: 8,
    cursor: "pointer",
    objectFit: "cover",
  },
  unsplashAttributionContainer: {
    marginLeft: 4,
    marginBottom: 16,
  },
  errorTextStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 48,
  },
  linkStyles: {
    display: "inline-block",
    marginLeft: 4,
    borderBottom: `1px solid ${deprecatedTones.gray7}`,
  },
  poweredByUnsplashContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    marginTop: 12,
  },
  svgContainer: {
    marginLeft: 8,
  },
});
