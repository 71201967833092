import { v4 as uuidv4 } from "uuid";

function snakeToCamel(s: string): string {
  return s.replace(/(_\w)/g, (m) => m[1].toUpperCase());
}

export function convertObjectKeysToCamelCase(
  obj: Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
): unknown {
  if (Array.isArray(obj)) {
    return obj.map(convertObjectKeysToCamelCase);
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = snakeToCamel(key);
      acc[camelCaseKey] = convertObjectKeysToCamelCase(obj[key]);
      return acc;
    }, {} as any); // eslint-disable-line @typescript-eslint/no-explicit-any
  }
  return obj;
}

export const pluralize = (
  noun: string,
  count: number,
  suffix = "s",
): string => {
  return `${noun}${count === 1 ? "" : suffix}`;
};

export function getDigits(str: string): string {
  return str.replace(/[^0-9]+/g, "");
}

export const formatMoney = (amount: number): string => {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const wasOrWere = (count: number): string => {
  return count === 1 ? "was" : "were";
};

export const listToReadableString = (li: string[]): string => {
  if (li.length === 0) return "";
  return li.length == 1
    ? li[0]
    : [li.slice(0, li.length - 1).join(", "), li[li.length - 1]].join(" and ");
};

export const uuid4 = (): string => {
  return uuidv4();
};

export const formatMultipleGroupNames = (
  groups: { name: string }[],
): string => {
  return groups.map((x) => x.name).join(", ");
};

export const formatGroupsList = (groups: { name: string }[]): string => {
  const sortedGroups = [...groups].sort((a, b) => a.name.localeCompare(b.name));
  return sortedGroups.map((g) => g.name).join(", ");
};

export const capitalizeFirstLetter = (word: string): string =>
  word[0].toUpperCase() + word.substring(1);

export const onlyFirstLetterCapitalized = (word: string): string =>
  capitalizeFirstLetter(word.toLowerCase());

export const stripHtml = (htmlString: string): string => {
  return htmlString.replaceAll(/<[^>]*>?/gm, "");
};

export const STANDARD_LANGUAGES: Language[] = ["en", "es", "fr"];
export const LANGUAGES: Language[] = [
  // language codes are ordered alphabetically by their english names
  "af",
  "sq",
  "am",
  "ar",
  "hy",
  "az",
  "bn",
  "eu",
  "be",
  "bs",
  "bg",
  "my",
  "ca",
  "ceb",
  "zh-CN",
  "zh-TW",
  "co",
  "hr",
  "cs",
  "da",
  "nl",
  "en",
  "eo",
  "et",
  "fil",
  "tl",
  "fi",
  "fr",
  "fr-CA",
  "fr-CH",
  "gl",
  "ka",
  "de",
  "de-CH",
  "el",
  "gu",
  "ht",
  "ha",
  "haw",
  "he",
  "hi",
  "hmn",
  "hu",
  "is",
  "ig",
  "id",
  "ga",
  "it",
  "ja",
  "jv",
  "kn",
  "kk",
  "km",
  "rw",
  "ko",
  "ku",
  "ky",
  "lo",
  "la",
  "lv",
  "lt",
  "lb",
  "mk",
  "mg",
  "ms",
  "ml",
  "mt",
  "mr",
  "mn",
  "mi",
  "ne",
  "no",
  "ny",
  "or",
  "ps",
  "fa",
  "pl",
  "pt",
  "pa",
  "ro",
  "ru",
  "sm",
  "gd",
  "sr",
  "sn",
  "sd",
  "si",
  "so",
  "st",
  "es",
  "es-ES",
  "es-MX",
  "es-US",
  "su",
  "sw",
  "sv",
  "tg",
  "ta",
  "tt",
  "te",
  "th",
  "tr",
  "tk",
  "uk",
  "ur",
  "ug",
  "uz",
  "bo",
  "vi",
  "cy",
  "fy",
  "xh",
  "yi",
  "yo",
  "zu",
];

export const LANG_CODE_TO_ENGLISH_NAME: { [key in Language]: string } = {
  af: "Afrikaans",
  am: "Amharic",
  ar: "Arabic",
  az: "Azerbaijani",
  be: "Belarusian",
  bg: "Bulgarian",
  bn: "Bangla",
  bs: "Bosnian",
  ca: "Catalan",
  ceb: "Cebuano",
  co: "Corsican",
  cs: "Czech",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  "de-CH": "German (Switzerland)",
  el: "Greek",
  en: "English",
  eo: "Esperanto",
  es: "Spanish",
  "es-ES": "Spanish (Spain)",
  "es-MX": "Spanish (Mexico)",
  "es-US": "Spanish (United States)",
  et: "Estonian",
  eu: "Basque",
  fa: "Persian",
  fi: "Finnish",
  fil: "Filipino",
  fr: "French",
  "fr-CA": "French (Canada)",
  "fr-CH": "French (Switzerland)",
  fy: "Western Frisian",
  ga: "Irish",
  gd: "Scottish Gaelic",
  gl: "Galician",
  gu: "Gujarati",
  ha: "Hausa",
  haw: "Hawaiian",
  he: "Hebrew",
  hi: "Hindi",
  hmn: "Hmong",
  hr: "Croatian",
  ht: "Haitian Creole",
  hu: "Hungarian",
  hy: "Armenian",
  id: "Indonesian",
  ig: "Igbo",
  is: "Icelandic",
  it: "Italian",
  ja: "Japanese",
  jv: "Javanese",
  ka: "Georgian",
  kk: "Kazakh",
  km: "Khmer",
  kn: "Kannada",
  ko: "Korean",
  ku: "Kurdish",
  ky: "Kyrgyz",
  la: "Latin",
  lb: "Luxembourgish",
  lo: "Lao",
  lt: "Lithuanian",
  lv: "Latvian",
  mg: "Malagasy",
  mi: "Māori",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  my: "Burmese",
  ne: "Nepali",
  nl: "Dutch",
  no: "Norwegian",
  ny: "Nyanja",
  or: "Odia",
  pa: "Punjabi",
  pl: "Polish",
  ps: "Pashto",
  pt: "Portuguese",
  ro: "Romanian",
  ru: "Russian",
  rw: "Kinyarwanda",
  sd: "Sindhi",
  si: "Sinhala",
  sm: "Samoan",
  sn: "Shona",
  so: "Somali",
  sq: "Albanian",
  sr: "Serbian",
  st: "Southern Sotho",
  su: "Sundanese",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  tg: "Tajik",
  th: "Thai",
  tk: "Turkmen",
  tl: "Tagalog",
  tr: "Turkish",
  tt: "Tatar",
  ug: "Uyghur",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  bo: "Tibetan",
  vi: "Vietnamese",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  "zh-CN": "Chinese",
  "zh-TW": "Chinese (Taiwan)",
  zu: "Zulu",
};

export const LANG_CODE_TO_LOCAL_NAME: { [key in Language]: string } = {
  af: "Afrikaans",
  am: "አማርኛ",
  ar: "العربية",
  az: "azərbaycan",
  be: "Belarusian",
  bg: "български",
  bn: "বাংলা",
  bs: "bosanski",
  ca: "català",
  ceb: "Cebuano",
  co: "Corsican",
  cs: "čeština",
  cy: "Welsh",
  da: "dansk",
  de: "Deutsch",
  "de-CH": "Deutsch (Schweiz)",
  el: "Ελληνικά",
  en: "English",
  eo: "Esperanto",
  es: "español",
  "es-ES": "español (España)",
  "es-MX": "español (México)",
  "es-US": "español (Estados Unidos)",
  et: "eesti",
  eu: "Basque",
  fa: "فارسی",
  fi: "suomi",
  fil: "Filipino",
  fr: "français",
  "fr-CH": "français (Suisse)",
  "fr-CA": "français (Canada)",
  fy: "Western Frisian",
  ga: "Irish",
  gd: "Scottish Gaelic",
  gl: "Galician",
  gu: "ગુજરાતી",
  ha: "Hausa",
  haw: "Hawaiian",
  he: "עברית",
  hi: "हिन्दी",
  hmn: "Hmong",
  hr: "hrvatski",
  ht: "Haitian Creole",
  hu: "magyar",
  hy: "Armenian",
  id: "Indonesia",
  ig: "Igbo",
  is: "Icelandic",
  it: "italiano",
  ja: "日本語",
  jv: "Javanese",
  ka: "Georgian",
  kk: "Kazakh",
  km: "ខ្មែរ",
  kn: "ಕನ್ನಡ",
  ko: "한국어",
  ku: "Kurdish",
  ky: "Kyrgyz",
  la: "Latin",
  lb: "Luxembourgish",
  lo: "Lao",
  lt: "lietuvių",
  lv: "latviešu",
  mg: "Malagasy",
  mi: "Māori",
  mk: "Macedonian",
  ml: "മലയാളം",
  mn: "Mongolian",
  mr: "मराठी",
  ms: "Melayu",
  mt: "Maltese",
  my: "Burmese",
  ne: "Nepali",
  nl: "Nederlands",
  no: "norsk",
  ny: "Nyanja",
  or: "Odia",
  pa: "ਪੰਜਾਬੀ",
  pl: "polski",
  ps: "Pashto",
  pt: "português",
  ro: "română",
  ru: "русский",
  rw: "Kinyarwanda",
  sd: "Sindhi",
  si: "Sinhala",
  sm: "Samoan",
  sn: "Shona",
  so: "Somali",
  sq: "Albanian",
  sr: "српски",
  st: "Southern Sotho",
  su: "Sundanese",
  sv: "svenska",
  sw: "Kiswahili",
  ta: "தமிழ்",
  te: "తెలుగు",
  tg: "Tajik",
  th: "ไทย",
  tk: "Turkmen",
  tl: "Filipino",
  tr: "Türkçe",
  tt: "Tatar",
  ug: "Uyghur",
  uk: "українська",
  ur: "اردو",
  uz: "o‘zbek",
  vi: "Tiếng Việt",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  "zh-CN": "中文（中国）",
  "zh-TW": "中文（台灣）",
  zu: "Zulu",
  bo: "བོད་ཡིག",
};

export function emojiToNumber(emoji: string): number {
  let number = 0;
  for (let i = 0; i < emoji.length; i++) {
    number += emoji.codePointAt(i) || 0;
  }
  return number;
}
