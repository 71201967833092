import { TranslationSetFragment } from "../fragments.generated";
import { uuid4 } from "@utils/strings";
import { PromptObjectInput, PromptType } from "@src/types.generated";

export const emptyTranslationSet = (
  input?: Partial<TranslationSetFragment>,
): TranslationSetFragment => {
  return {
    uuid: uuid4(),
    en: "",
    translationsDisabled: false,
    translationOverrides: [],
    ...input,
  };
};

export const emptyPromptInput = (): PromptObjectInput => {
  return {
    uuid: uuid4(),
    type: PromptType.Message,
    selectAllThatApply: false,
    assignMedia: false,
    responseOptions: [],
  };
};
