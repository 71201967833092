import { useUploadFileMutation } from "@hooks/useFileUpload.generated";
import { useToast } from "@hooks/useToast";
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  // editor
  locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  // plugins
  setPlugins,
} from "@pqina/pintura";
// @ts-expect-error
import { pintura } from "@pqina/pintura/pintura.module.css";
import { PinturaEditorModal } from "@pqina/react-pintura";
import { FC } from "react";

setPlugins(plugin_crop, plugin_filter, plugin_annotate);

const editorDefaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
};

type BaseProps = {
  imageUrl: string;
  visible: boolean;
  hide: () => void;
  aspectRatio?: number;
};

type WithNewImageFile = {
  onNewImageFile: (file: File) => void;
  onNewImageUrl?: never;
};

type WithNewImageUrl = {
  onNewImageFile?: never;
  onNewImageUrl: (imageUrl: string) => void;
};

type Props = BaseProps & (WithNewImageFile | WithNewImageUrl);

const PinturaModal: FC<Props> = ({
  imageUrl,
  onNewImageFile,
  onNewImageUrl,
  visible,
  hide,
  aspectRatio,
}) => {
  const { addErrorToast } = useToast();
  const [uploadFile] = useUploadFileMutation({
    onCompleted: (data) => {
      if (data.uploadFile.success && data.uploadFile.fileUrl) {
        onNewImageUrl ? onNewImageUrl(data.uploadFile.fileUrl) : undefined;
      } else {
        addErrorToast({
          ...data.uploadFile.error,
          callsite: "upload_pintura_edited_file",
        });
      }
    },
    onError: (error) => {
      addErrorToast({
        error,
        callsite: "upload_pintura_edited_file",
      });
    },
  });
  if (!visible) {
    return null;
  }
  return (
    <PinturaEditorModal
      src={imageUrl}
      {...editorDefaults}
      imageCropAspectRatio={aspectRatio}
      className={pintura}
      onHide={hide}
      onProcess={({ dest }) => {
        onNewImageFile ? onNewImageFile(dest) : undefined;
        if (onNewImageUrl) {
          uploadFile({ variables: { file: dest } });
        }
      }}
    />
  );
};

export default PinturaModal;
