import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpenGraphDataQueryVariables = Types.Exact<{
  url: Types.Scalars['String'];
}>;


export type OpenGraphDataQuery = { __typename?: 'Query', OpenGraphData: { [key: string]: any } };


export const OpenGraphDataDocument = gql`
    query OpenGraphData($url: String!) {
  OpenGraphData(url: $url)
}
    `;

/**
 * __useOpenGraphDataQuery__
 *
 * To run a query within a React component, call `useOpenGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenGraphDataQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useOpenGraphDataQuery(baseOptions: Apollo.QueryHookOptions<OpenGraphDataQuery, OpenGraphDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenGraphDataQuery, OpenGraphDataQueryVariables>(OpenGraphDataDocument, options);
      }
export function useOpenGraphDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenGraphDataQuery, OpenGraphDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenGraphDataQuery, OpenGraphDataQueryVariables>(OpenGraphDataDocument, options);
        }
export type OpenGraphDataQueryHookResult = ReturnType<typeof useOpenGraphDataQuery>;
export type OpenGraphDataLazyQueryHookResult = ReturnType<typeof useOpenGraphDataLazyQuery>;
export type OpenGraphDataQueryResult = Apollo.QueryResult<OpenGraphDataQuery, OpenGraphDataQueryVariables>;