import { useEffect, useState, useCallback } from "react";

const DEFAULT_TIMEOUT = 1000;

type Args = {
  timeout?: number;
  wait?: boolean;
  func: () => void;
};

export type State = {
  debouncedFunc: (now?: boolean) => void;
  pendingFuncCall: boolean;
  hasCalledFunc: boolean;
};

export const useDebounce = ({
  func,
  wait = false,
  timeout = DEFAULT_TIMEOUT,
}: Args): State => {
  const [shouldSave, setShouldSave] = useState(false);
  const [saveTimeoutID, setSaveTimeoutId] = useState<null | number>(null);
  const [hasCalledFunc, setHasCalledFunc] = useState(false);

  const initializeSave = useCallback(() => {
    if (shouldSave && !wait) {
      setShouldSave(false);
      setHasCalledFunc(true);
      func();
      setSaveTimeoutId(null);
    }
  }, [shouldSave, setShouldSave, func, wait]);

  useEffect(() => {
    window.setTimeout(initializeSave);
  }, [initializeSave]);

  useEffect(
    () => () => {
      if (saveTimeoutID !== null) {
        clearTimeout(saveTimeoutID);
      }
    },
    [saveTimeoutID],
  );

  const debouncedFunc = useCallback(
    (now = false) => {
      if (saveTimeoutID !== null) {
        clearTimeout(saveTimeoutID);
      }

      const t: number = window.setTimeout(
        () => {
          setShouldSave(true);
        },
        now ? 0 : timeout,
      );
      setSaveTimeoutId(t);
    },
    [saveTimeoutID, timeout],
  );

  const pendingFuncCall = wait || !!saveTimeoutID;
  return {
    debouncedFunc,
    pendingFuncCall,
    hasCalledFunc,
  };
};
