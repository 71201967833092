import UnsplashImageSearch from "@components/ui/UnsplashImageSearch";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { StyleSheet } from "aphrodite";
import { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import { MAX_FILE_SIZE_BYTES } from "@utils/files";

type Props = {
  updateImage: (url: string) => void;
  onFileSelected: (file: File) => void;
  fileUploadInProgress: boolean;
};

const CoverImagePickerImagesTab: FC<Props> = ({
  updateImage,
  onFileSelected,
  fileUploadInProgress,
}) => {
  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      if (acceptedFiles.length === 1) {
        onFileSelected(acceptedFiles[0]);
      }
    },
    [onFileSelected],
  );
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      useFsAccessApi: true,
      multiple: false,
      noClick: false,
      noKeyboard: true,
      onDrop,
      maxSize: MAX_FILE_SIZE_BYTES,
      accept: {
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
      },
    });
  return (
    <AutoLayout
      direction="vertical"
      styleDeclaration={styles.imageTabContainer}
    >
      <AutoLayout
        direction="vertical"
        alignmentHorizontal="center"
        alignmentVertical="center"
        styleDeclaration={[
          styles.uploadFileSection,
          isDragActive && styles.draggingFileOverDropArea,
        ]}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <AutoLayout
          direction="vertical"
          alignmentHorizontal="center"
          alignmentVertical="center"
          styleDeclaration={styles.fileUploadImageContainer}
        >
          <AutoLayout alignmentHorizontal="center" alignmentVertical="center">
            <AutoLayout
              direction="vertical"
              alignmentHorizontal="center"
              spaceBetweenItems={6}
            >
              <Text type={"P3"} fontWeight="Medium">
                Drag image to upload or click to browse files
              </Text>
              <AutoLayout height={16} style={{ maxHeight: 16 }}>
                {fileUploadInProgress ? (
                  <Spinner size={16} />
                ) : fileRejections[0]?.errors[0] ? (
                  <Text type={"P3"} color={deprecatedTones.red9}>
                    {fileRejections[0].errors[0].message}
                  </Text>
                ) : (
                  <Text type={"P3"}>.jpeg, .png supported</Text>
                )}
              </AutoLayout>
            </AutoLayout>
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
      <UnsplashImageSearch
        containerStyleDeclaration={styles.imageSearchContainer}
        imageContainerStyleDeclaration={styles.imageContainer}
        imageStyleDeclaration={styles.images}
        onImageSelect={(image) => updateImage(image.urls.regular)}
        showPhotoCredit={false}
        orientation={"squarish"}
      />
    </AutoLayout>
  );
};

export default CoverImagePickerImagesTab;

const styles = StyleSheet.create({
  imageTabContainer: {
    overflow: "scroll",
    marginTop: 15,
  },
  emojiTabContainer: {
    height: 300,
    width: 350,
    maxHeight: 300,
    marginBottom: 15,
  },
  imageSearchContainer: {
    width: 340,
  },
  imageContainer: {
    margin: "0px 0px 2px 0px",
  },
  images: {
    height: 82,
    width: 82,
    padding: 0,
  },
  uploadFileSection: {
    alignSelf: "stretch",
    height: 75,
    border: `2px dashed ${deprecatedTones.gray5}`,
    borderColor: deprecatedTones.gray5Alpha,
    borderRadius: 8,
    backgroundColor: deprecatedColors.surface,
    ":hover": {
      cursor: "pointer",
    },
  },
  fileUploadImageContainer: {
    height: 87,
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
  },
  spinner: {
    marginBottom: 8,
  },
  draggingFileOverDropArea: {
    focus: {
      boxShadow: `0 0 0 3px ${deprecatedColors.primaryContainer}`,
      border: `1px solid ${deprecatedTones.blue5}`,
    },
    focusError: {
      boxShadow: `0 0 0 3px ${deprecatedColors.errorContainer}`,
      border: `1px solid ${deprecatedTones.red11}`,
    },
    dropdownShadow: {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.03)",
    },
    backgroundColor: deprecatedTones.gray4Alpha,
  },
});
