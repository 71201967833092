export const shadows: { [key: string]: string } = {
  elevation1:
    "0px 0px 2px rgba(142, 145, 146, 0.3), 0px 0px 3px 1px rgba(142, 145, 146, 0.15)",
  elevation2:
    "0px 0px 2px rgba(142, 145, 146, 0.3), 0px 0px 6px 2px rgba(142, 145, 146, 0.15)",
  elevation3:
    "0px 0px 8px 3px rgba(142, 145, 146, 0.15), 0px 0px 3px 2px rgba(142, 145, 146, 0.15)",
  elevation4:
    "0px 0px 10px 4px rgba(142, 145, 146, 0.10), 0px 0px 3px 4px rgba(142, 145, 146, 0.10)",
  elevation5:
    "0px 0px 12px 6px rgba(142, 145, 146, 0.15), 0px 0px 4px 5px rgba(142, 145, 146, 0.15)",
  elevation6: "0px 4px 12px 0px rgba(0, 0, 0, 0.04)",
  elevation7: "0px 6px 12px 4px rgba(0, 0, 0, 0.08)",
  elevation8:
    "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 160px rgba(0, 0, 0, 0.08)",
  paneShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.03);",
  phoneOutline:
    "0px 1.433px 2.865px -0.955px rgba(20, 21, 26, 0.03), 0px 0px 0.955px 0px rgba(0, 0, 0, 0.15), 0px 5.731px 19.103px 0px rgba(0, 0, 0, 0.06)",
  dropdownShadow:
    "0px 16px 32px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)",
} as const;
