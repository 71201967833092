import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";

type Props = {
  initialValue: string;
  delay?: number;
};
type Return = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  debouncedValue: string;
};

export const useDebouncedInputValue = ({
  initialValue,
  delay = 300,
}: Props): Return => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);
  const debouncer = useMemo(() => {
    return debounce(setDebouncedValue, delay);
  }, [setDebouncedValue, delay]);
  useEffect(() => {
    debouncer(value);
  }, [value, debouncer]);

  return useMemo(
    () => ({
      value,
      setValue,
      debouncedValue,
    }),
    [value, setValue, debouncedValue],
  );
};
