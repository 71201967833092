import { ToastErrorInfo } from "@contexts/ToastContext";
import { useUploadFileMutation } from "@hooks/useFileUpload.generated";
import { useToast } from "@hooks/useToast";
import { MAX_FILE_SIZE_BYTES } from "@utils/files";
import gql from "graphql-tag";
import { useCallback, useState } from "react";

export type MediaFragment = {
  url: string;
  name: string;
};

export const useFileUpload = (
  onFileUploadSuccess: (mediaFile: MediaFragment) => void,
): [(file: File) => void, { loading: boolean }] => {
  const { addErrorToast } = useToast();
  const [inputFileName, setInputFileName] = useState<string>("");
  const [uploadFile, { loading }] = useUploadFileMutation({
    onCompleted: (data) => {
      if (data.uploadFile.success && data.uploadFile.fileUrl) {
        onFileUploadSuccess({
          url: data.uploadFile.fileUrl,
          name: inputFileName,
        });
      } else {
        addErrorToast({
          ...data.uploadFile.error,
          callsite: "upload_file",
        });
      }
    },
    onError: (error) => {
      addErrorToast({
        ...error,
        callsite: "upload_file",
      });
    },
  });
  const validateImage = (file: File): Promise<void> => {
    return new Promise<void>(
      (resolve, reject: (error: ToastErrorInfo) => void) => {
        if (file.size > MAX_FILE_SIZE_BYTES) {
          reject({
            message:
              "Image file size too big. Must be less than 50MB and under 10,000 pixels in both width and height.",

            fileSize: file.size,
            callsite: "image_upload_too_large",
          });
          return;
        }
        if (file.type === "image/gif" && file.size > 5000000) {
          reject({
            message: "GIF file size too big. Must be less than 5MB.",

            fileSize: file.size,
            callsite: "gif_upload_too_large",
          });
          return;
        }
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width > 10000 || img.height > 10000) {
            reject({
              message:
                "Image must be less than 10,000 pixels in both width and height.",

              callsite: "image_upload_too_big",
            });
          } else {
            resolve();
          }
        };
        img.onerror = () => {
          reject({
            message: "Invalid image file",
            callsite: "image_upload_invalid_file",
          });
        };
      },
    );
  };

  const handleFileEvent = useCallback(
    async (file: File) => {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        addErrorToast({
          message:
            "That file is too large. If it's a video, try uploading it to Vimeo or YouTube and pasting in the link.",

          fileSize: file.size,
          callsite: "file_upload_too_large",
        });
        return;
      }

      const isImage = file.type.startsWith("image/");
      try {
        if (isImage) {
          await validateImage(file);
        }
        setInputFileName(file.name);
        uploadFile({ variables: { file } });
      } catch (error: unknown) {
        addErrorToast({
          iconType: "alert",
          success: false,
          ...(error as ToastErrorInfo),
        });
      }
    },
    [uploadFile, setInputFileName, addErrorToast],
  );
  return [handleFileEvent, { loading }];
};

gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(mediaFile: $file) {
      success
      error {
        code
      }
      fileUrl
    }
  }
`;
