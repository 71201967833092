import { css, StyleDeclaration } from "aphrodite";
import React, { FC, MouseEvent as ReactMouseEvent } from "react";

type Props = {
  jc?: React.CSSProperties["justifyContent"];
  ai?: React.CSSProperties["alignItems"];
  flex?: React.CSSProperties["flex"];
  children?: React.ReactNode;
  style?: React.CSSProperties;
  styleDeclaration?: StyleDeclaration;
  onClick?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const FlexRow: FC<Props> = (
  {
    children,
    jc = "flex-start",
    ai = "center",
    flex,
    style,
    styleDeclaration,
    onClick,
  },
  ref,
) => {
  return (
    <div
      ref={ref}
      onClick={onClick}
      className={css(styleDeclaration)}
      style={{
        justifyContent: jc,
        alignItems: ai,
        display: "flex",
        flex: flex,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

// @ts-ignore
export default React.forwardRef(FlexRow);
