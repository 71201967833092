import React, { FC } from "react";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";

type Props = {
  gradientStyle: StyleDeclaration;
  selected: boolean;
  onClick: () => void;
};

const BackgroundGradientIcon: FC<Props> = ({
  gradientStyle,
  selected,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={css([
        styles.container,
        gradientStyle,
        selected && styles.selected,
      ])}
    ></div>
  );
};

export default BackgroundGradientIcon;

const styles = StyleSheet.create({
  container: {
    width: 24,
    height: 24,
    borderRadius: 48,
    margin: 5,
    cursor: "pointer",
  },
  selected: {
    border: "3px solid white",
    height: 30,
    width: 30,
    margin: 2,
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)",
  },
});
