import React from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";

type Props = React.ComponentProps<typeof AutoLayout> & {
  size: number;
};

const Circle = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <AutoLayout
      ref={ref}
      height={props.size}
      width={props.size}
      alignmentVertical={"center"}
      alignmentHorizontal={"center"}
      {...props}
      style={{
        borderRadius: props.size / 2,
        ...props.style,
      }}
    />
  );
});

export default Circle;
